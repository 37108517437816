// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ToDoList .back {
  margin: 0 1em;
  padding: 1em;
  float: left;
}

.ToDoList .item {
  border: 1px solid lightgray;
  padding: 1em;
  margin: 1em;
  cursor: pointer;
  display: flex;
}

.ToDoList .label {
  margin-left: 1ex;
}

.ToDoList .checked .label {
  text-decoration: line-through;
  color: lightgray;
}`, "",{"version":3,"sources":["webpack://./src/ToDoList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB","sourcesContent":[".ToDoList .back {\n  margin: 0 1em;\n  padding: 1em;\n  float: left;\n}\n\n.ToDoList .item {\n  border: 1px solid lightgray;\n  padding: 1em;\n  margin: 1em;\n  cursor: pointer;\n  display: flex;\n}\n\n.ToDoList .label {\n  margin-left: 1ex;\n}\n\n.ToDoList .checked .label {\n  text-decoration: line-through;\n  color: lightgray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
