// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../layers/paketo-buildpacks_npm-install/launch-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListToDoLists .summary {
  border: 1px solid lightgray;
  padding: 1em;
  margin: 1em;
  cursor: pointer;
  display: flex;
}

.ListToDoLists .count {
  padding-left: 1ex;
  color: blueviolet;
  font-size: 92%;
}`, "",{"version":3,"sources":["webpack://./src/ListTodoLists.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,YAAY;EACZ,WAAW;EACX,eAAe;EACf,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".ListToDoLists .summary {\n  border: 1px solid lightgray;\n  padding: 1em;\n  margin: 1em;\n  cursor: pointer;\n  display: flex;\n}\n\n.ListToDoLists .count {\n  padding-left: 1ex;\n  color: blueviolet;\n  font-size: 92%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
